import { useAuthContext } from '@pata-app/ui-auth';
import { Header, Footer } from '@pata-app/ui-document';
import { useLoginModalContext } from '@pata-app/ui-login-modal';

import React from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useGetUserDetails } from '../../hooks/useGetUserDetails';
import { MainContainer } from './appWrapper.styled';
import { IAppWrapper } from './appWrapper.types';

function getImageUrl(imagePath?: string | null) {
  if (!imagePath) return '';

  if (imagePath.includes('googleusercontent')) {
    return imagePath;
  }

  return `${process.env.CDN_BASE_URL_USER_CONTENT}/${imagePath}`;
}

const AppWrapper = ({ children }: IAppWrapper) => {
  const { isAuthenticated, logout, isAuthLoading } = useAuthContext();
  const { setIsOpen } = useLoginModalContext();

  const location = useLocation();

  const handleLogout = () => {
    logout({ global: true });
    window.location.reload();
  };

  const { data } = useGetUserDetails();

  const user = data?.data?.data;

  const userInfo = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    imageUrl: getImageUrl(user?.profilePicture),
  };

  return (
    <>
      <Header
        domain=""
        isAuthenticated={isAuthenticated}
        isAuthLoading={isAuthLoading}
        userInfo={userInfo}
        onLogoutPress={handleLogout}
        onSignupPress={() => setIsOpen('register')}
        onLoginPress={() => setIsOpen('login')}
        partnerAppLink={location.pathname.includes('business') ? process.env.PARTNERS_BASE_URL : undefined}
      />
      <MainContainer>{children}</MainContainer>
      <ToastContainer
        autoClose={5000}
        closeOnClick
        hideProgressBar
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="bottom-right"
        rtl={false}
        theme="dark"
      />
      <Footer baseUrl={process.env.WEB_BASE_URL} />
    </>
  );
};

export default AppWrapper;
